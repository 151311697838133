import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import AppStoreButton from './app-store.svg';

import './navbar.scss';

const Navbar = () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "DuckDrills-Logo-Yellow.png" }) {
                childImageSharp {
                    fixed(height: 36, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <nav className="Navbar">
            <ul className="Navbar__logo">
                <li>
                    <Img alt="Duck Drills" fixed={data.placeholderImage.childImageSharp.fixed} />
                </li>
            </ul>

            <ul className="Navbar__links">
                <li>
                    <AppStoreButton />
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;

