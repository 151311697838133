import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Navbar from './navbar';

import './header.scss';

const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "dd-ipad-vertical.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <header className="Header">
            <Navbar />
            <div className="Header__pitch">
                <div className="Header__preview">
                    <Img alt="Duck Drills" fluid={data.placeholderImage.childImageSharp.fluid} />
                </div>
                <div className="Header__text">
                    <h1>Level Up Your Art Skills Fast.</h1>
                    <p>
                        Duck Drills provides drill sheets and starter templates for your
                        favorite iPad drawing applications such as Procreate, Sketchbook Pro,
                        Adobe Fresco &amp; Photoshop and others.
                    </p>
                </div>
            </div>
        </header>
    );
};

export default Header
